import React from 'react';
import './Hero.css';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Hero = () => {
   
    const navigate = useNavigate();

    return (
        <section className="hero">
            <div className="hero-content">
                <h2>AMÉLIOREZ VOTRE BIEN-ÊTRE ET AIDEZ À CRÉER UN MONDE PLUS SAIN.</h2>
                {/* <h2>Plus de Santé, Moins de Toxicité.</h2> */}
                <h4>Rejoignez-nous maintenant et commencez à transformer votre
                santé et la planète!</h4>
                <HashLink smooth to="/#produits">
                <button className="hero-btn">COMMANDEZ</button>
                </HashLink>
            </div>
            <div className="hero-image">
                <img src="/hero_image2.png" alt="NETEROS" />
            </div>
        </section>
    );
};

export default Hero;