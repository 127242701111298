import React, { useEffect, useRef } from 'react';
import './ScrollSection.css';

const ScrollSection = () => {
  const paragraphRefs = useRef([]);

  useEffect(() => {
    const paragraphs = paragraphRefs.current;

    const observerOptions = {
        root: null,
        threshold: 1,
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                entry.target.classList.remove('fade-out');
            } else {
                entry.target.classList.add('fade-out');
            }
        });
    }, observerOptions);

    paragraphs.forEach((paragraph) => {
        if (paragraph instanceof Element) observer.observe(paragraph);
    });

    return () => {
        paragraphs.forEach((paragraph) => {
            if (paragraph instanceof Element) observer.unobserve(paragraph);
        });
    };
}, []);


  return (
    <section className="scroll-section">
      <div className="content-container">
        <div className="fixed-container">
          <h2 className="section-title">QU'EST-CE QU'UNE PORTION (2 CAPSULES)</h2>
          <img
            src="/product1.png"
            alt="Product"
            className="section-image"
          />
        </div>
        <div className="paragraphs-container">
          <div className="left-paragraphs">
            <p ref={(el) => (paragraphRefs.current[0] = el)}>
              <strong>Augmentation d'énergie:</strong> Stimulez votre énergie et votre endurance tout au long de la journée.
            </p>
            <p ref={(el) => (paragraphRefs.current[2] = el)}>
              <strong>Favorise la combustion des graisses:</strong> Optimisez la perte de graisse grâce à une meilleure activation métabolique.
            </p>
            <p ref={(el) => (paragraphRefs.current[4] = el)}>
              <strong>Élimination de l'excès d'eau:</strong> Aidez votre corps à se débarrasser de la rétention d'eau superflue.
            </p>
          </div>
          <div className="right-paragraphs">
            <p ref={(el) => (paragraphRefs.current[1] = el)}>
              <strong>Amélioration de la circulation:</strong> Boostez votre circulation sanguine pour une meilleure performance à l'entraînement.
            </p>
            <p ref={(el) => (paragraphRefs.current[3] = el)}>
              <strong>Digestion optimisée:</strong> Facilitez la digestion des protéines pour un confort intestinal amélioré.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScrollSection;
