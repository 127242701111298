import React, { useState } from 'react';
import './MissionVisionValues.css';

const MissionVisionValues = () => {
    const [selectedSection, setSelectedSection] = useState('mission');

    const content = {
        mission: {
            title: "Mission",
            text: "Guider la génération actuelle vers un mode de vie sain et naturel en fournissant des produits naturels, simples, et non toxiques, qui contribuent au bien-être."
        },
        vision: {
            title: "Vision",
            text: "Construire un monde pour les générations futures, offrant un accès à des produits naturels sans toxines ni maladies causées par les produits transformés actuels."
        },
        values: {
            title: "Valeurs",
            text: (
                <ul>
                    <li><strong>Authenticité:</strong> Produits transparents, naturels et basés sur l'expertise nutritionnelle.</li>
                    <li><strong>Simplicité:</strong> Intégration facile au quotidien.</li>
                    <li><strong>Durabilité:</strong> Préservation de la santé et de l’environnement.</li>
                    <li><strong>Engagement:</strong> Inspirer un avenir sain et conscient.</li>
                </ul>
            )
        }
    };

    return (
        <section id="mission-vision-values" className="mission-vision-values">
            <h2>CE QUI NOUS DÉFINIT</h2>
            {/* Promo Section */}
            <div className="promo-section">
                <div className="promo" onClick={() => setSelectedSection('mission')}>
                    <div className="image-wrapper" style={{ '--overlay-color': 'CadetBlue' }}>
                        <img
                            src="/mission3.jpg"
                            alt="Mission"
                        />
                    </div>
                    <h3 className="title" data-cta="En savoir plus">Mission</h3>
                </div>
                <div className="promo" onClick={() => setSelectedSection('vision')}>
                    <div className="image-wrapper" style={{ '--overlay-color': 'CadetBlue' }}>
                        <img
                            src="/vision3.jpg"
                            alt="Vision"
                        />
                    </div>
                    <h3 className="title" data-cta="En savoir plus">Vision</h3>
                </div>
                <div className="promo" onClick={() => setSelectedSection('values')}>
                    <div className="image-wrapper" style={{ '--overlay-color': 'CadetBlue' }}>
                        <img
                            src="/values3.jpg"
                            alt="Valeurs"
                        />
                    </div>
                    <h3 className="title" data-cta="En savoir plus">Valeurs</h3>
                </div>
            </div>

            {/* Content Section */}
            <div className="content-section">
                {selectedSection && (
                    <div className="content">
                        <p>{content[selectedSection].text}</p>
                    </div>
                )}
            </div>
        </section>
    );
};

export default MissionVisionValues;
