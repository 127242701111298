import React, { useState } from 'react';
import './FooterSection.css';
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';

const FooterSection = () => {
    const [showLinks, setShowLinks] = useState(false);

    const toggleLinks = () => {
        setShowLinks(!showLinks);
    };

    return (
        <footer className="footer-section">
            <div className="footer-bottom">
                <p>&copy;NETEROSHEALTH 2024</p>
                <div className="social-icons">
                    <FaFacebookF />
                    <FaInstagram />
                    <FaTiktok />
                </div>
            </div>
        </footer>
    );
};

export default FooterSection;
