// TestimonialsSection.js
import React, { useState } from 'react';
import './TestimonialsSection.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const testimonials = [
    {
        id: 1,
        rating: 5,
        text: "Ces produits ont changé ma routine ! En seulement quelques semaines, j'ai retrouvé un niveau d'énergie incroyable, et mes performances sportives se sont améliorées. J'adore leur approche naturelle et leur efficacité.",
        author: "Yassine, 29 ans, Marrakech",
    },
    {
        id: 2,
        rating: 5,
        text: "Je me sens revitalisé(e) depuis que j'ai commencé à utiliser ces compléments. Mon énergie est au rendez-vous toute la journée, et j'ai même remarqué une amélioration de ma digestion. C'est exactement ce dont j'avais besoin pour un quotidien actif et équilibré !",
        author: "Amina, 34 ans, Casablanca",
    },
    // Add more testimonials as needed
];

const TestimonialsSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <section className="testimonials-section">
            <h2>TESTÉS ET ADORÉS !</h2>
            <p>En plus d'être conçus et testés cliniquement... Voici quelques retours !</p>
            <div className="testimonial-card">
                <div className="rating">
                    {Array.from({ length: testimonials[currentIndex].rating }).map((_, index) => (
                        <span key={index}>&#9733;</span> // Star symbol
                    ))}
                </div>
                <p className="testimonial-text">“ {testimonials[currentIndex].text} ”</p>
                <p className="author">- {testimonials[currentIndex].author}</p>
                <div className="navigation">
                    <button onClick={handlePrev} className="nav-button nav-button-left">
                        <FaArrowLeft />
                    </button>
                    <button onClick={handleNext} className="nav-button nav-button-right">
                        <FaArrowRight />
                    </button>
                </div>
                <div className="pagination">
                    {testimonials.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentIndex ? 'active' : ''}`}
                            onClick={() => setCurrentIndex(index)}
                        ></span>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TestimonialsSection;
