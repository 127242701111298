import React, { useState } from 'react';
import './CheckoutPage.css';
import { useNavigate } from 'react-router-dom';

const CheckoutPage = ({ cartItems, setCartItems }) => {
    const [formData, setFormData] = useState({
        fullName: '',

        phoneNumber: '',
        address: '',
    });
    const [promoCode, setPromoCode] = useState(''); // Promo code input
    const [discount, setDiscount] = useState(0); // Discount amount
    const [notification, setNotification] = useState(null); // For toast notifications
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const DELIVERY_FEE = 30; // Fixed delivery fee
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handlePromoCodeApply = async () => {
        const subtotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    
        try {
            const response = await fetch('https://neteroshealth.com/backend/google_sheets.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'validatePromoCode',
                    promoCode,
                    subtotal,
                }),
            });
    
            const data = await response.json();
    
            if (data.success) {
                setDiscount(data.discount);
                setNotification({
                    message: `Code promo appliqué avec succès ! Réduction: ${data.discount} dh`,
                    type: 'success',
                });
            } else {
                setDiscount(0);
                const message = 
                    data.error === 'expired' 
                        ? 'Code promo expiré.' 
                        : data.error === 'invalid'
                        ? 'Code promo invalide.'
                        : 'Erreur lors de l’application du code promo.';
                setNotification({
                    message,
                    type: 'error',
                });
            }
        } catch (error) {
            console.error('Error applying promo code:', error);
            setNotification({
                message: 'Une erreur est survenue lors de l’application du code promo.',
                type: 'error',
            });
        } finally {
            setTimeout(() => setNotification(null), 4000);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const subtotal = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
        const total = subtotal - discount + DELIVERY_FEE;

        const orderData = {
            action: 'submitOrder',
            customerDetails: formData,
            cartItems,
            subtotal,
            discount,
            total,
            promoCode,
        };

        try {
            const response = await fetch('https://neteroshealth.com/backend/google_sheets.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(orderData),
            });
            if (response.ok) {
                setNotification({
                    message: 'Commande passée avec succès ! Merci pour votre achat.',
                    type: 'success',
                });
                setFormData({ fullName: '', phoneNumber: '', address: '' });
                setCartItems([]);
                setTimeout(() => navigate('/'), 3000);
            } else {
                setNotification({
                    message: 'Erreur lors de la soumission. Veuillez réessayer.',
                    type: 'error',
                });
            }
        } catch (error) {
            console.error('Error submitting order:', error);
            setNotification({
                message: 'Une erreur est survenue. Veuillez réessayer.',
                type: 'error',
            });
        } finally {
            setIsSubmitting(false);
            setTimeout(() => setNotification(null), 4000);
        }
    };

    const subtotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    const total = subtotal - discount + DELIVERY_FEE;

    return (
        <div className="checkout-page">
            {notification && (
                <div className={`toast-notification ${notification.type}`}>
                    {notification.message}
                </div>
            )}
            <h2>Commandez Maintenant!</h2>
            <div className="checkout-container">
                <form className="checkout-form" onSubmit={handleSubmit}>
                    <label>
                        Nom et prénom
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    <label>
                        Numéro de téléphone
                        <input
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    <label>
                        Addresse
                        <textarea
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                        ></textarea>
                    </label>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Envoi en cours...' : 'Placer la commande'}
                    </button>
                </form>
                <div className="checkout-cart">
                    <h3>Votre Panier</h3>
                    <ul>
                        {cartItems.map((item) => (
                            <li key={item.id} className="checkout-cart-item">
                                <img
                                    src={item.images[0] || '/placeholder.png'}
                                    alt={item.name}
                                    className="checkout-cart-item-image"
                                />
                                <div className="checkout-cart-item-details">
                                    <span className="checkout-cart-item-name">{item.name}</span>
                                    <span className="checkout-cart-item-quantity">
                                        Quantité: {item.quantity}
                                    </span>
                                    <span className="checkout-cart-item-price">
                                        Prix: {item.price * item.quantity} dh
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="checkout-cart-summary">
                        <div className="checkout-cart-subtotal">
                            <span>Sous-total:</span> <span><strong>{subtotal} dh</strong></span>
                        </div>
                        <div className="checkout-cart-delivery">
                            <span>Frais de livraison:</span> <span><strong>{DELIVERY_FEE} dh</strong></span>
                        </div>
                        <div className="checkout-cart-discount">
                            <span>Réduction:</span> <span><strong>-{discount} dh</strong></span>
                        </div>
                        <div className="checkout-cart-total">
                            <strong>Total:</strong> {total} dh
                        </div>
                    </div>
                    <div className="promo-code-section">
                        <input
                            type="text"
                            placeholder="Code Promo"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                        />
                        <button type="button" onClick={handlePromoCodeApply}>
                            Appliquer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutPage;
