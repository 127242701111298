import React from 'react';
import Hero from '../components/Hero';
import AboutSection from '../components/AboutSection';
import StandardsSection from '../components/StandardsSection';
import ScrollSection from '../components/ScrollSection';
import ProductSection from '../components/ProductSection';
import MissionVisionValues from  '../components/MissionVisionValues';
import TestimonialsSection from '../components/TestimonialsSection';
import FooterSection from '../components/FooterSection';

const HomePage = () => {
    return (
        <div>
            <Hero />
            <AboutSection />
            <StandardsSection />
            <ScrollSection />
            <ProductSection />
            <MissionVisionValues />
            <TestimonialsSection />
            <FooterSection />
        </div>
    );
};

export default HomePage;