import React, { useRef, useState } from 'react';
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import './ContactPage.css';

const ContactPage = () => {
    const formRef = useRef();
    const [notification, setNotification] = useState(null); 
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_yi51qss',
                'template_nl2app8',
                formRef.current,
                '-U7i1GtOwZFXIiZQz'
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setNotification({
                        message: 'Votre message a été envoyé avec succès!',
                        type: 'success',
                    });
                },
                (error) => {
                    console.error(error.text);
                    setNotification({
                        message: 'Une erreur s\'est produite. Veuillez réessayer plus tard.',
                        type: 'error',
                    });
                }
            );

        e.target.reset();
    };

    return (
        <div className="contact-page">
                {notification && (
                <div className={`toast-notification ${notification.type}`}>
                    {notification.message}
                </div>
            )}
            <h2>NOUS SOMMES À VOTRE ÉCOUTE!</h2>
            <div className="contact-container">
                <form ref={formRef} className="contact-form" onSubmit={sendEmail}>
                    <p>
                        Pour toute demande d'informations ou de collaboration, n'hésitez pas à nous contacter!
                        Si vous êtes une Entreprise ou des Professionnels, veuillez détailler votre besoin.
                    </p>
                    <div className="input-row">
                        <input
                            name="from_name"
                            className="placeholder-style"
                            type="text"
                            placeholder="Nom"
                            required
                        />
                        <input
                            name="email"
                            className="placeholder-style"
                            type="email"
                            placeholder="E-mail"
                            required
                        />
                    </div>
                    <div className="input-row-2">
                        <input
                            name="phone"
                            className="placeholder-style"
                            type="text"
                            placeholder="Téléphone"
                            required
                        />
                        <textarea
                            name="message"
                            className="placeholder-style"
                            placeholder="Message"
                            required
                        ></textarea>
                        <button type="submit">Envoyer</button>
                    </div>
                </form>
                <div className="contact-info">
                    <div className="contact-me">
                        <h3>Coordonnées</h3>
                        <p>Téléphone: +212678700790 (WhatsApp)</p>
                        <p>Email: contact@beautheskin.com</p>
                        <h4>Réseaux sociaux</h4>
                        <div className="social-icons">
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF />
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <FaInstagram />
                            </a>
                            <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">
                                <FaTiktok />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
