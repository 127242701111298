import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaShoppingCart, FaBars } from 'react-icons/fa';
import CartDropdown from './CartDropdown';
import './Header.css';
import { HashLink } from 'react-router-hash-link';

const Header = ({ cartItems, setCartItems, cartOpen, setCartOpen }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const cartIconRef = useRef(null);
    const dropdownRef = useRef(null);
    const lastScrollY = useRef(0);
    const menuRef = useRef(null);

    const toggleMenu = () => setMenuOpen(!menuOpen);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuOpen && menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                cartIconRef.current &&
                dropdownRef.current &&
                !cartIconRef.current.contains(event.target) &&
                !dropdownRef.current.contains(event.target)
            ) {
                setCartOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY <= 0) {
                setIsHeaderVisible(true);
            } else if (currentScrollY > lastScrollY.current) {
                setIsHeaderVisible(false);
            } else {
                setIsHeaderVisible(true);
            }

            lastScrollY.current = currentScrollY;
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <header className="header">
            <div className={`header-main ${isHeaderVisible ? 'visible' : 'hidden'}`}>
                <Link to="/" className="logo">
                    <img src="/logo_small.png" alt="NETEROS Logo" />
                </Link>

                <FaBars className="menu-toggle" onClick={toggleMenu} />

                <nav className={`nav-links ${menuOpen ? 'open' : ''}`}   ref={menuRef} >
                    <NavLink to="/" exact activeClassName="active" onClick={()=>{setMenuOpen(false)}}>
                        Accueil
                    </NavLink>
                    <HashLink smooth to="/#produits" activeClassName="active" onClick={()=>{setMenuOpen(false)}}>
                        Produits
                    </HashLink>
                    <HashLink smooth to="/#mission-vision-values" activeClassName="active" onClick={()=>{setMenuOpen(false)}}>
                        À Propos
                    </HashLink>
                    <NavLink to="/contact" activeClassName="active" onClick={()=>{setMenuOpen(false)}}>
                        Contact
                    </NavLink>
                </nav>
                <div className="header-actions">
                    <div
                        className="icon-container"
                        ref={cartIconRef}
                        onClick={() => setCartOpen(!cartOpen)} 
                    >
                        <FaShoppingCart className="icon" />
                    </div>
                    {cartOpen && (
                        <div ref={dropdownRef}>
                            <CartDropdown
                                cartItems={cartItems}
                                setCartItems={setCartItems}
                                setCartOpen={setCartOpen}
                            />
                        </div>
                    )}
                </div>

            </div>
        </header>
    );
};

export default Header;
