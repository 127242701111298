import React from 'react';
import './AboutSection.css';

const AboutSection = () => {
    return (
        <section className="about-section">
            <div className="about-content">
                <h2>CHAQUE GESTE COMPTE. ENSEMBLE, NOUS POUVONS CHANGER LE MONDE.</h2>
                <p>Nous sommes certifiés par:</p>
                <div className="certification-logos">
                    <img src="/dmp.png" alt="Certification Logo 1" />
                    <img src="/onssa-logo.png" alt="Certification Logo 2" />
                    <img src="/iso.png" alt="Certification Logo 3" />
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
