// StandardsSection.js
import React from 'react';
import './StandardsSection.css';

const StandardsSection = () => {
    const standards = [
        { icon: '/gluten-free2.png', label: 'Gluten Free' },
        { icon: '/sugar-free2.png', label: 'Sugar Free' },
        { icon: '/gmo-free2.png', label: 'GMO Free' },
        { icon: '/dairy-free2.png', label: 'Dairy Free' },
        { icon: '/soy-free2.png', label: 'Soy Free' },
    ];

    return (
        <section className="standards-section">
            <div className="standards-container">
                {/* <h3>Pourquoi Nous?</h3> */}
                <h2>NOS NORMES</h2>
                <div className="standards-icons">
                    {standards.map((standard, index) => (
                        <div key={index} className="standard-item">
                            <img src={standard.icon} alt={standard.label} />
                            <p>{standard.label}</p>
                        </div>
                    ))}
                </div>
                <h4>Backed by Science</h4>
            </div>
        </section>
    );
};

export default StandardsSection;
